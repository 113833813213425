import React, { useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Spinner,
	Stack,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useToast,
	useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';
import globalStore from '../store';
import { DataSelector } from '@repo/alictus-common/types/segment';
import { DataSource } from '@repo/alictus-common/enums/data_source';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import dataSelectorSchema from '@repo/alictus-common/validation/data_selector_schema';

import { Field, Form, Formik } from 'formik';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';

type NestedMenuItem = {
	[key: string]: NestedMenuItem | null;
};

export function SelectorModal({
	isOpen,
	onClose,
	selectorInstance,
}: {
	isOpen: boolean;
	onClose: () => void;
	selectorInstance?: DataSelector;
}) {
	const createEmptyDataSelector = (): DataSelector => {
		return {
			id: 0,
			creationDate: 0,
			isDeleted: false,
			isOverWritten: false,
			lastUpdated: 0,
			uid: '',
			userId: 0,
			name: '',
			description: '',
			dataSource: DataSource.Playfab,
			fieldName: '',
			isDate: false,
			isMock: isMockCreator,
		};
	};

	let globalData = globalStore();
	const toast = useToast();
	const isMockCreator = globalData.user?.roles.includes('MOCK_CREATOR') || false;
	const [dataSelector, setDataSelector] = useState<DataSelector>(createEmptyDataSelector());
	const [showSubmitButton, setShowSubmitButton] = useState(true);
	const [playfabUserId, setPlayfabUserId] = useState('');
	const [playfabUserData, setPlayfabUserData] = useState<NestedMenuItem>({});
	const [isFetching, setIsFetching] = useState(false);
	const [isDate, setIsDate] = useState(false);
	const [isMock, setIsMock] = useState(isMockCreator);
	const [selectedPath, setSelectedPath] = useState<string>('');
	const { onClose: onCloseModal } = useDisclosure();
	const [warningMessage, setWarningMessage] = useState('');

	useEffect(() => {
		if (selectorInstance) {
			console.log('Selector instance', selectorInstance);
			setDataSelector(selectorInstance);
			setIsDate(selectorInstance.isDate);
			setIsMock(selectorInstance.isMock);
			setSelectedPath(selectorInstance.fieldName);
		} else {
			// If creating a new selector, ensure synchronization
			setDataSelector(createEmptyDataSelector());
			setIsMock(isMockCreator);
		}
	}, [selectorInstance, isMockCreator]);

	const resetForm = () => {
		setDataSelector(createEmptyDataSelector());
	};

	function updateDataSelector(dataSelectorField: Partial<DataSelector>) {
		setDataSelector({ ...dataSelector, ...dataSelectorField });
		console.log('Updated data selector', dataSelector);
	}

	function handleClose() {
		setShowSubmitButton(true);
		resetForm();
		onClose();
	}

	// Recursive rendering of menu items
	const renderMenuItems = (
		menuData: NestedMenuItem,
		currentPath: string = '',
		onCloseModal: () => void,
		setFieldValue: (path: string) => void,
	) => {
		if (!menuData || typeof menuData !== 'object') return null;

		return Object.entries(menuData).map(([key, value]) => {
			const newPath = currentPath ? `${currentPath}.${key}` : key;

			if (value && typeof value === 'object') {
				// Nested menu for items with children
				return (
					<Menu key={key} placement="right-start" isLazy>
						<MenuButton paddingLeft={'auto'} as={MenuItem} _hover={{ background: 'orange.700' }} closeOnSelect={false}>
							{key} <ChevronRightIcon ml="2" />
						</MenuButton>
						<MenuList paddingLeft={'auto'} maxHeight="400px" overflowY="auto">
							{renderMenuItems(value, newPath, onCloseModal, setFieldValue)}
						</MenuList>
					</Menu>
				);
			}

			// Leaf node menu item
			return (
				<MenuItem
					paddingLeft={'auto'}
					_hover={{ background: 'yellow.400' }}
					key={key}
					onClick={() => {
						setSelectedPath(newPath);
						updateDataSelector({ fieldName: newPath });
						setFieldValue(newPath);
						onCloseModal();
					}}
				>
					{key}
				</MenuItem>
			);
		});
	};

	const handleCreateSelector = async (event?: React.FormEvent) => {
		if (event) event.preventDefault();
		try {
			setShowSubmitButton(false);
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/selector';
			await axios.post(url, dataSelector, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } }).then((response) => {
				console.log(response);
				handleClose();
			});
		} catch (error: any) {
			console.error(error);
			toast({
				title: 'Error Creating Selector',
				description: error.response?.data,
				status: 'error',
				duration: 10000,
				isClosable: true,
				position: 'top',
			});
		} finally {
			setShowSubmitButton(true);
		}
	};

	const handleUpdateSelector = async (selectorId: number) => {
		try {
			setShowSubmitButton(false);
			const updatedDataSelector = { ...dataSelector, isMock };
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/selector/' + selectorId;
			await axios.put(url, updatedDataSelector, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } }).then((response) => {
				console.log(response);
				handleClose();
			});
		} catch (error: any) {
			console.error(error);
			toast({
				title: 'Error Updating Selector',
				description: error.response?.data,
				status: 'error',
				duration: 10000,
				isClosable: true,
				position: 'top',
			});
		} finally {
			setShowSubmitButton(true);
		}
	};

	const onPlayfabDataFetch = async () => {
		if (!playfabUserId.trim()) {
			setWarningMessage('Playfab ID cannot be empty.');
			return;
		}

		setWarningMessage('');

		setIsFetching(true);
		let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/playfab_data/' + playfabUserId;
		await axios
			.get(url, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } })
			.then((response) => {
				//const extractedKeys = Object.keys(response.data);
				//setPlayfabUserData(extractedKeys);
				setPlayfabUserData(response.data);
			})
			.finally(() => setIsFetching(false));
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				{selectorInstance ? <ModalHeader>Update Selector</ModalHeader> : <ModalHeader>Create Selector</ModalHeader>}
				<ModalCloseButton />
				<Formik
					initialValues={{
						name: selectorInstance?.name || '',
						description: selectorInstance?.description || '',
						fieldName: selectorInstance?.fieldName || '',
					}}
					validationSchema={toFormikValidationSchema(dataSelectorSchema)}
					onSubmit={async (values, actions) => {
						if (selectorInstance === undefined) {
							await handleCreateSelector(selectorInstance);
						}

						if (selectorInstance !== null) {
							await handleUpdateSelector(selectorInstance!.id);
						}
						actions.setSubmitting(false);
					}}
				>
					{(props) => (
						<Form>
							<ModalBody>
								<Stack spacing={4}>
									<Field name="name">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl isInvalid={!!props.errors.name && props.touched.name} isRequired>
												<FormLabel>Name</FormLabel>
												<Input
													{...field}
													value={dataSelector.name}
													onChange={(event) => {
														if (event) {
															form.setFieldValue('name', event.target.value);
															updateDataSelector({ name: event.target.value });
														}
													}}
												/>
												<FormErrorMessage>{props.errors.name}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Field name="description">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl isInvalid={!!props.errors.description && props.touched.description} isRequired>
												<FormLabel>Description</FormLabel>
												<Input
													{...field}
													value={dataSelector.description}
													onChange={(event) => {
														if (event) {
															form.setFieldValue('description', event.target.value);
															updateDataSelector({ description: event.target.value });
														}
													}}
												/>
												<FormErrorMessage>{form.errors.description}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<FormControl>
										<FormLabel>Template Playfab Id</FormLabel>
										<HStack>
											<Input
												value={playfabUserId}
												onChange={(event) => setPlayfabUserId(event.target.value)}
												placeholder="Enter Playfab ID"
											/>
											{isFetching ? <Spinner /> : <Button onClick={onPlayfabDataFetch}>Fetch</Button>}
										</HStack>
										{warningMessage && (
											<Text color="red.500" fontSize="sm" mt={2}>
												{warningMessage}
											</Text>
										)}
									</FormControl>
									<Field name="fieldNamePlayfabID">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl flex="1" minW={'200px'}>
												<Menu onClose={onCloseModal}>
													<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
														User Playfab Data
													</MenuButton>
													<MenuList paddingLeft={'auto'} maxHeight="300px" overflowY="auto">
														{renderMenuItems(playfabUserData, '', onCloseModal, (selectedPath) => {
															form.setFieldValue('fieldName', selectedPath);
														})}
													</MenuList>
												</Menu>
											</FormControl>
										)}
									</Field>
									<Field name="fieldName">
										{({ field, form }: { field: any; form: any }) => (
											<FormControl isInvalid={!!props.errors.fieldName && props.touched.fieldName} isRequired>
												<FormLabel>Selected Field Name</FormLabel>
												<Input
													{...field}
													value={selectedPath}
													onChange={(event) => {
														if (event) {
															const value = event.target.value;
															setSelectedPath(value); // Update the state to reflect the typed value
															form.setFieldValue('fieldName', value); // Update Formik's value
															updateDataSelector({ fieldName: value });
														}
													}}
												/>
												<FormErrorMessage>{form.errors.fieldName}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<HStack justifyContent={'space-between'}>
										<Checkbox
											isChecked={isDate}
											onChange={() => {
												setIsDate(!isDate);
												updateDataSelector({ isDate: !isDate });
											}}
										>
											Is Date
										</Checkbox>
										<Checkbox
											isDisabled={!isMockCreator}
											isChecked={isMock}
											onChange={() => {
												const newIsMock = !isMock; // Calculate the new value
												setIsMock(newIsMock); // Update local state
												updateDataSelector({ isMock: newIsMock }); // Use the new value
											}}
										>
											Is Mock
										</Checkbox>
									</HStack>
								</Stack>
								<ModalFooter padding={'20px'}>
									<Button variant="outline" mr={3} onClick={onClose}>
										Cancel
									</Button>

									<Button colorScheme="blue" isLoading={props.isSubmitting} isDisabled={!props.isValid} type="submit">
										{selectorInstance === undefined ? 'Create' : 'Update'}
									</Button>
								</ModalFooter>
							</ModalBody>
						</Form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
}

export default SelectorModal;
