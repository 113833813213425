import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
	Box,
	Button,
	Grid,
	GridItem,
	HStack,
	Image,
	Text,
	Table,
	TableContainer,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Spinner,
} from '@chakra-ui/react';
import { FaSyncAlt } from 'react-icons/fa';
import config from '../config';
import globalStore from '../store';
import axios from 'axios';
import { Build, CodemagicBuilds } from '@repo/alictus-common/types/build';
import CancelBuildModal from './cancel_build_modal';
import dayjs from 'dayjs';

const BuildsPage = () => {
	const [builds, setBuilds] = useState<Build[]>([]);
	const [loading, setLoading] = useState(false);
	const [nextRefreshIn, setNextRefreshIn] = useState(60);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedBuild, setSelectedBuild] = useState<Build | null>(null);

	let globalData = globalStore();

	const fetchBuilds = async () => {
		setLoading(true);
		try {
			let bearerToken = 'Bearer ' + globalData.JWTToken;
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/codemagic_builds';
			const response = await axios.get(url, { headers: { Authorization: bearerToken } });

			const data: CodemagicBuilds = response.data.build_list;
			if (data) {
				setBuilds(data);
			} else {
				console.error('Builds field is missing in the response');
			}
		} catch (error) {
			console.error('Error fetching builds:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchBuilds();

		const interval = setInterval(() => {
			fetchBuilds();
			setNextRefreshIn(60);
		}, 60000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const countdown = setInterval(() => {
			setNextRefreshIn((prev) => (prev > 0 ? prev - 1 : 0));
		}, 1000);

		return () => clearInterval(countdown);
	}, []);

	const runningOrPendingBuilds = builds.filter(
		(build) =>
			build.status === 'building' ||
			build.status === 'finishing' ||
			build.status === 'queued' ||
			build.status === 'fetching' ||
			build.status === 'preparing' ||
			build.status === 'publishing',
	);
	const completedBuilds = builds.filter((build) => build.status === 'finished' || build.status === 'failed' || build.status === 'canceled');

	return (
		<Box padding={4}>
			<HStack justify="flex-end" mb={4}>
				<HStack spacing={8}>
					<Text fontSize="sm" color="gray.400">
						Auto-refresh in {nextRefreshIn}s
					</Text>
					<HStack>
						<Button leftIcon={<FaSyncAlt />} colorScheme="blue" onClick={fetchBuilds} isDisabled={loading}>
							Refresh
						</Button>
					</HStack>
				</HStack>
			</HStack>

			<Grid templateRows="1fr 1fr" gap={4}>
				{/* Running/Pending Builds Section */}
				<GridItem>
					<Box border="1px" borderColor="gray.300" borderRadius="md" p={4} overflowY="auto" maxHeight="370px" minHeight="370px">
						<Text fontWeight="bold" mb={2}>
							Running/Pending Builds
						</Text>
						{loading ? (
							<Spinner />
						) : runningOrPendingBuilds.length > 0 ? (
							<BuildTable
								builds={runningOrPendingBuilds}
								onOpen={onOpen}
								onClose={onClose}
								isOpen={isOpen}
								setSelectedBuild={setSelectedBuild}
							/>
						) : (
							<Text>No running or pending builds.</Text>
						)}
					</Box>
				</GridItem>

				{/* Completed Builds Section */}
				<GridItem>
					<Box border="1px" borderColor="gray.300" borderRadius="md" p={4} overflowY="auto" maxHeight="370px" minHeight="370px">
						<Text fontWeight="bold" mb={2}>
							Completed Builds
						</Text>
						{loading ? (
							<Spinner />
						) : completedBuilds.length > 0 ? (
							<BuildTable builds={completedBuilds} onOpen={onOpen} onClose={onClose} isOpen={isOpen} setSelectedBuild={setSelectedBuild} />
						) : (
							<Text>No completed builds.</Text>
						)}
					</Box>
				</GridItem>
			</Grid>

			{/* Cancel Build Modal */}
			{selectedBuild && (
				<CancelBuildModal
					isOpen={isOpen}
					onClose={() => {
						setSelectedBuild(null); // Reset selected build
						onClose(); // Close the modal
					}}
					build={selectedBuild}
				/>
			)}
		</Box>
	);
};

// BuildTable component now not exported as it's used only within BuildsPage
const BuildTable = ({
	builds,
	onOpen,
	onClose,
	isOpen,
	setSelectedBuild,
}: {
	builds: any[];
	onOpen: () => void;
	onClose: () => void;
	isOpen: boolean;
	setSelectedBuild: (build: any | null) => void;
}) => {
	const getRowColor = (status: string) => {
		switch (status) {
			case 'finished':
				return 'green.700';
			case 'failed':
				return 'red.700';
			case 'canceled':
				return 'purple.700';
			case 'building':
				return 'blue.700';
			case 'queued':
				return 'yellow.700';
			case 'fetching':
			case 'preparing':
			case 'publishing':
			case 'finishing':
				return 'orange.500';
			default:
				return 'white';
		}
	};

	const handleCancelClick = (build: any) => {
		setSelectedBuild(build); // Set selected build
		onOpen(); // Open the cancel modal
	};

	const formatDate = (date: string | null) => {
		if (!date) return '-';
		return dayjs(date).format('HH:mm:ss DD-MM-YYYY');
	};

	return (
		<TableContainer>
			<Table variant="simple" size="sm">
				<Thead sx={{ th: { textAlign: 'center', verticalAlign: 'middle' } }}>
					<Tr>
						<Th width="250px" maxW="200px" minW="200px">
							Name
						</Th>
						<Th width="200px">Branch</Th>
						<Th width="100px">Version</Th>
						<Th width="100px">Build Number</Th>
						<Th width="100px">Status</Th>
						<Th width="200px">Start Time</Th>
						<Th width="200px">End Time</Th>
						<Th width="200px">Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{builds.map((build) => (
						<Tr key={build._id} bg={getRowColor(build.status)}>
							<Td
								width="250px"
								maxW="250px"
								minW="250px"
								verticalAlign="middle"
								textAlign="left"
								whiteSpace="nowrap"
								overflow="hidden"
								textOverflow="ellipsis"
								_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
							>
								<HStack spacing={2}>
									{build.appIconUrl && (
										<Box boxSize="40px" flexShrink={0}>
											<Image src={build.appIconUrl} alt="App Icon" objectFit="contain" borderRadius="md" />
										</Box>
									)}
									<Box
										as="span"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
										overflow="hidden"
										maxWidth="250px"
										_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
									>
										{build.appName}
									</Box>
								</HStack>
							</Td>
							<Td
								textAlign="center"
								verticalAlign="middle"
								width={'200px'}
								maxW={'200px'}
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								position="relative"
								_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
							>
								{build.branch}
							</Td>
							<Td
								textAlign="center"
								verticalAlign="middle"
								width={'100px'}
								maxW={'100px'}
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								position="relative"
								_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
							>
								{build.artefacts.length > 0 ? build.artefacts[0].version : '-'}
							</Td>
							<Td
								textAlign="center"
								verticalAlign="middle"
								width={'100px'}
								maxW={'100px'}
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								position="relative"
								_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
							>
								{build.artefacts.length > 0 ? build.artefacts[0].versionCode : '-'}
							</Td>
							<Td
								textAlign="center"
								verticalAlign="middle"
								width={'100px'}
								maxW={'100px'}
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								position="relative"
								_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
							>
								{build.status}
							</Td>
							<Td
								textAlign="center"
								verticalAlign="middle"
								width={'200px'}
								maxW={'200px'}
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								position="relative"
								_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
							>
								{formatDate(build.startedAt)}
							</Td>
							<Td
								textAlign="center"
								verticalAlign="middle"
								width={'200px'}
								maxW={'200px'}
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								position="relative"
								_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
							>
								{formatDate(build.finishedAt)}
							</Td>
							<Td
								textAlign="center"
								verticalAlign="middle"
								width={'200px'}
								maxW={'200px'}
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								position="relative"
								_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
							>
								{['building', 'queued', 'preparing'].includes(build.status) && (
									<Button colorScheme="red" size="xs" onClick={() => handleCancelClick(build)}>
										Cancel
									</Button>
								)}
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default BuildsPage;
