import { useEffect } from 'react';
import axios from 'axios';
import config from '../../config';

import globalStore, { GlobalStore } from '../../store';
import { RemoteConfigValue } from '@repo/alictus-common/types';

export function useRemoteConfigData() {
	let globalData = globalStore();

	useEffect(() => {
		const fetchDataAsync = async () => {
			await streamRcData(globalData);
		};

		fetchDataAsync().then(() => {
			console.log('Remote Config Data Fetched');
		});
	}, []);
}

async function streamRcData(globalData: GlobalStore) {
	let lastUpdated = 0; // implement this later
	try {
		while (true) {
			let bearerToken = 'Bearer ' + globalData.JWTToken;
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/rc_package/stream/' + lastUpdated;
			const response = await axios.get(url, { headers: { Authorization: bearerToken } });
			let incomingRemoteConfigValues = response.data as RemoteConfigValue[];
			incomingRemoteConfigValues = incomingRemoteConfigValues.filter(
				(rcValue) => globalData.user!.roles.includes('MOCK_CREATOR') || !rcValue.isMock,
			);
			incomingRemoteConfigValues = incomingRemoteConfigValues.filter((rcValue) => rcValue.abTestUid === null);

			if (incomingRemoteConfigValues) {
				//console.log("setting remote config values to incoming data");
				globalData.setRemoteConfigValues(incomingRemoteConfigValues);
				//lastUpdated = getLatestUpdate(incomingRemoteConfigValues);
			}

			// Add a delay to prevent infinite loop
			await new Promise((resolve) => setTimeout(resolve, 5000));
		}
	} catch (error) {
		console.error('Error fetching remote config data:', error);
	}
}

function getLatestUpdate(rcValues: RemoteConfigValue[]): number {
	return Math.max(...rcValues.map((value) => value.lastUpdated));
}
