import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Text,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import config from '../config';
import globalStore from '../store';

export function CancelBuildModal({ isOpen, onClose, build }: { isOpen: boolean; onClose: () => void; build: any }) {
	let globalData = globalStore();
	const toast = useToast();

	// Guard against undefined build
	if (!build) return null;

	const handleCancel = async () => {
		try {
			let bearerToken = 'Bearer ' + globalData.JWTToken;
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + `/codemagic_builds/${build._id}/cancel`;
			const response = await axios.post(url, null, { headers: { Authorization: bearerToken } });

			if (response.status === 200) {
				toast({
					title: 'Build Canceled',
					description: `The build "${build.appName}" has been canceled.`,
					status: 'success',
					duration: 5000,
					position: 'top',
					isClosable: true,
				});
			} else {
				toast({
					title: 'Error Canceling Build',
					description: `Failed to cancel build "${build.appName}".`,
					status: 'error',
					position: 'top',
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			console.error('Error canceling build:', error);
			toast({
				title: 'Error Canceling Build',
				description: `Failed to cancel build "${build.appName}".`,
				status: 'error',
				position: 'top',
				duration: 5000,
				isClosable: true,
			});
		} finally {
			onClose(); // Close the modal
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Cancel Build</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>Are you sure you want to cancel the build "{build.appName}"?</Text>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={handleCancel}>
						Yes, Cancel
					</Button>
					<Button ml={3} onClick={onClose}>
						No, Close
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default CancelBuildModal;
