import {
	Button,
	Box,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Grid,
	GridItem,
	HStack,
	VStack,
	Flex,
	IconButton,
	Input,
	Tooltip,
} from '@chakra-ui/react';
import { Icon, PlusSquareIcon, ViewIcon, SearchIcon } from '@chakra-ui/icons';
import { useDisclosure } from '@chakra-ui/hooks';
import React, { useState, useEffect, useRef } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import config from '../config';
import globalStore, { GlobalStore } from '../store';
import { formatTimestamp } from '../utility';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { CgCloseO } from 'react-icons/cg';
import { DataSelector } from '@repo/alictus-common/types/segment';
import SelectorModal from './selector_modal';
import DeleteSelectorModal from './delete_selector_modal';

async function fetchSelectorData(globalData: GlobalStore, setSelectors: React.Dispatch<React.SetStateAction<DataSelector[]>>) {
	let lastUpdated = 0; // implement this later
	try {
		while (true) {
			//console.log("fetchSelectorData last updated timestamp: " + lastUpdated);
			let bearerToken = 'Bearer ' + globalData.JWTToken;
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/selector';
			const response = await axios.get(url, { headers: { Authorization: bearerToken } });
			let dataSelectors = response.data as DataSelector[];
			dataSelectors = dataSelectors.filter((selector) => globalData.user!.roles.includes('MOCK_CREATOR') || !selector.isMock);
			//console.log("Segment Data: " + JSON.stringify(dataSelectors));
			if (dataSelectors) {
				//console.log("setting segment values");
				setSelectors(dataSelectors);
				//lastUpdated = getLatestUpdate(incomingRemoteConfigValues);
			}

			// Add a delay to prevent infinite loop
			await new Promise((resolve) => setTimeout(resolve, 1000));
		}
	} catch (error) {
		console.error('Error fetching remote config data:', error);
	}
}

function SelectorDataView() {
	let globalData = globalStore();
	const createSelector = useDisclosure();
	const editSelector = useDisclosure();
	const deleteSelector = useDisclosure();
	const [selectorToDelete, setSelectorToDelete] = useState<DataSelector>();
	const [selectedSelector, setSelectedSelector] = useState<DataSelector>();
	const [dataSelectors, setDataSelectors] = useState<DataSelector[]>([]);
	const hasFetchedData = useRef(false);
	const [sortField, setSortField] = useState<'name' | 'lastUpdated' | 'creationDate'>('name');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
	const [searchQuery, setSearchQuery] = useState(''); // State for search query
	const [isSearchActive, setIsSearchActive] = useState(false);

	useEffect(() => {
		if (!hasFetchedData.current) {
			console.log('Fetching selector data');
			hasFetchedData.current = true;
			fetchSelectorData(globalData, setDataSelectors);
		}
	}, []);

	const handleSort = (field: 'name' | 'lastUpdated' | 'creationDate') => {
		if (sortField === field) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortField(field);
			setSortDirection('asc');
		}
	};

	const filteredData = (dataSelectors || []).filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

	const sortedData = filteredData.sort((a, b) => {
		let result = 0;
		if (sortField === 'name') {
			result = a.name.localeCompare(b.name);
		} else if (sortField === 'lastUpdated') {
			result = new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
		} else if (sortField === 'creationDate') {
			result = new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
		}
		return sortDirection === 'asc' ? result : -result;
	});

	const multiFunction = (disc: { onOpen: () => void }, selectorInstance: DataSelector) => {
		setSelectedSelector(selectorInstance);
		disc.onOpen();
	};

	const deleteSelectorModalOpen = (disc: { onOpen: () => void }, selectorInstance: DataSelector) => {
		setSelectorToDelete(selectorInstance);
		disc.onOpen();
	};

	function showCheckMark(isActive: boolean) {
		return (
			<Flex justify="center" align="center" width="100%" height="100%">
				{isActive ? (
					<Icon as={BsFillCheckCircleFill} color={'green.200'} _hover={{ color: 'gray.300' }} />
				) : (
					<Icon as={CgCloseO} color={'red.200'} _hover={{ color: 'gray.300' }} />
				)}
			</Flex>
		);
	}

	function ViewButton({ onOpen }: { onOpen: () => void }) {
		return (
			<IconButton
				onClick={onOpen}
				aria-label="Add"
				icon={<ViewIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
				backgroundColor={'transparent'}
				_hover={{ bg: 'transparent', color: 'white' }}
			/>
		);
	}

	function PlusButton({ paddingTop, onOpen }: { paddingTop?: string; onOpen: () => void }) {
		return (
			<IconButton
				onClick={onOpen}
				paddingTop={paddingTop}
				aria-label="Add"
				icon={<PlusSquareIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
				backgroundColor={'transparent'}
				_hover={{ bg: 'transparent', color: 'white' }}
			/>
		);
	}

	return (
		<Box border={'2px'} borderRadius={'5px'} borderColor={'gray.600'}>
			{deleteSelector.isOpen && selectorToDelete !== undefined && (
				<DeleteSelectorModal selectorInstance={selectorToDelete} disclosure={deleteSelector} />
			)}

			{editSelector.isOpen && (
				<SelectorModal isOpen={editSelector.isOpen} onClose={editSelector.onClose} selectorInstance={selectedSelector} />
			)}

			{createSelector.isOpen && <SelectorModal isOpen={createSelector.isOpen} onClose={createSelector.onClose} />}

			<VStack spacing={1} align="stretch">
				<HStack flex={1} align={'center'}>
					<Box paddingLeft={'10px'}>
						<strong>Data Selectors</strong>
					</Box>
					<PlusButton onOpen={createSelector.onOpen} paddingTop={'0px'} />
				</HStack>
				<TableContainer>
					<Table variant="simple" size="md" border={'0px'}>
						<Thead>
							<Tr key={'header'}>
								<Th width="200px" cursor="pointer" maxW="200px" minW="200px">
									<Flex align="center" gap="15px" height="20px">
										<Tooltip label="Search by Name" placement="top">
											<Icon
												as={SearchIcon}
												boxSize={4}
												cursor="pointer"
												onClick={(e) => {
													e.stopPropagation();
													setIsSearchActive(!isSearchActive);
												}}
											/>
										</Tooltip>
										{isSearchActive && (
											<Input
												size="sm"
												placeholder="Search..."
												value={searchQuery}
												onChange={(e) => setSearchQuery(e.target.value)}
												autoFocus
												width="100%"
											/>
										)}
										{!isSearchActive && (
											<span onClick={() => handleSort('name')}>Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}</span>
										)}
									</Flex>
								</Th>
								<Th width="200px">Description</Th>
								<Th width="200px" onClick={() => handleSort('creationDate')} cursor="pointer">
									Creation Date {sortField === 'creationDate' && (sortDirection === 'asc' ? '↑' : '↓')}
								</Th>
								<Th width="150px" onClick={() => handleSort('lastUpdated')} cursor="pointer">
									Last Update {sortField === 'lastUpdated' && (sortDirection === 'asc' ? '↑' : '↓')}
								</Th>
								<Th width="50px">
									<Flex justify="center" align="center" width="100%" height="100%">
										View
									</Flex>
								</Th>
								<Th width="50px">
									<Flex justify="center" align="center" width="100%" height="100%">
										Delete
									</Flex>
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{sortedData.map((field: DataSelector, i: number) => (
								<Tr key={i}>
									<Td
										width="200px"
										maxW="200px"
										overflow="hidden"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
										position="relative"
										_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
									>
										{field.name}
									</Td>
									<Td
										width="200px"
										maxW="200px"
										overflow="hidden"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
										position="relative"
										_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
									>
										{field.description}
									</Td>
									<Td width="200px">{formatTimestamp(field.creationDate)}</Td>
									<Td width="150px">{formatTimestamp(field.lastUpdated)}</Td>
									<Td>
										<Flex justify="center" align="center" width="100%" height="100%">
											<ViewButton onOpen={() => multiFunction(editSelector, field)} />
										</Flex>
									</Td>
									<Td width="50px">
										<Flex justify="center" align="center" width="100%" height="100%">
											<Button
												onClick={() => {
													deleteSelectorModalOpen(deleteSelector, field); /*setDeleteRCKeyData(field);*/
												}}
												leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
												variant="simple"
											/>
										</Flex>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</VStack>
		</Box>
	);
}

function SelectorView() {
	return (
		<Grid
			height={'1px'}
			width={'100%'}
			templateRows="repeat(4, 1fr)"
			templateColumns="repeat(5, 1fr)"
			gap={2}
			paddingLeft={'12px'}
			paddingRight={'10px'}
		>
			<GridItem colSpan={6} padding={'10px'}></GridItem>
			<GridItem paddingLeft={'5px'} paddingRight={'5px'} colSpan={6}>
				<SelectorDataView />
			</GridItem>
		</Grid>
	);
}
export default SelectorView;
