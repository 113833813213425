import {
	Button,
	Box,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Grid,
	GridItem,
	HStack,
	VStack,
	Flex,
	Menu,
	MenuButton,
	Input,
	MenuGroup,
	MenuList,
	IconButton,
	Tooltip,
	useToast,
} from '@chakra-ui/react';
import { Icon, PlusSquareIcon, ViewIcon, SearchIcon } from '@chakra-ui/icons';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';
import { useDisclosure } from '@chakra-ui/hooks';
import React, { useState, useEffect, useRef } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import config from '../config';
import globalStore, { GlobalStore } from '../store';
import { formatTimestamp } from '../utility';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { CgCloseO } from 'react-icons/cg';
import { PlayerSegment } from '@repo/alictus-common/types/segment';
import SegmentModal from './segment_modal';
import DeleteSegmentModal from './delete_segment_modal';

function SegmentationView2() {
	let globalData = globalStore();
	const createSegment = useDisclosure();
	const editSegment = useDisclosure();
	const [selectedSegment, setSelectedSegment] = useState<PlayerSegment>();
	const [playerSegments, setPlayerSegments] = useState<PlayerSegment[]>([]);
	const deleteSegmentDisclosure = useDisclosure();
	const [segmentToDelete, setSegmentToDelete] = useState<PlayerSegment>();
	const hasFetchedData = useRef(false);
	const isMounted = useRef(true);
	const toast = useToast();
	const [copies, setCopies] = useState(1);
	const [sortField, setSortField] = useState<'name' | 'lastUpdated' | 'creationDate' | 'isTest'>('name');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
	const [searchQuery, setSearchQuery] = useState(''); // State for search query
	const [isSearchActive, setIsSearchActive] = useState(false);

	useEffect(() => {
		if (!hasFetchedData.current) {
			console.log('Fetching segmentation data');
			hasFetchedData.current = true;
			fetchSegmentationData(globalData, setPlayerSegments, isMounted);
		}
		return () => {
			isMounted.current = true; // This will stop the polling when the component unmounts
		};
	}, []);

	const multiFunction = (disc: { onOpen: () => void }, segmentInstance: PlayerSegment) => {
		//console.log('Selected Segment: ', segmentInstance);
		setSelectedSegment(segmentInstance);
		disc.onOpen();
	};

	const handleSort = (field: 'name' | 'lastUpdated' | 'creationDate' | 'isTest') => {
		if (sortField === field) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortField(field);
			setSortDirection('asc');
		}
	};

	const filteredData = (playerSegments || []).filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

	const sortedData = filteredData.sort((a, b) => {
		let result = 0;
		if (sortField === 'name') {
			result = a.name.localeCompare(b.name);
		} else if (sortField === 'lastUpdated') {
			result = new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
		} else if (sortField === 'creationDate') {
			result = new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
		} else if (sortField === 'isTest') {
			result = (a[sortField] ? 1 : 0) - (b[sortField] ? 1 : 0);
		}
		return sortDirection === 'asc' ? result : -result;
	});

	async function fetchSegmentationData(
		globalData: GlobalStore,
		setPlayerSegments: React.Dispatch<React.SetStateAction<PlayerSegment[]>>,
		isMounted: React.RefObject<boolean>,
	) {
		let lastUpdated = 0; // implement this later
		try {
			while (isMounted.current) {
				//console.log("Last Updated: " + lastUpdated);
				let bearerToken = 'Bearer ' + globalData.JWTToken;
				let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/segment';
				const response = await axios.get(url, { headers: { Authorization: bearerToken } });
				let playerSegments = response.data as PlayerSegment[];
				playerSegments = playerSegments.filter((segment) => globalData.user!.roles.includes('MOCK_CREATOR') || !segment.isMock);

				//console.log('Player Segments: ');
				//console.log(playerSegments.map((segment) => segment));
				if (playerSegments) {
					//console.log('Updated Segments : ', playerSegments);
					setPlayerSegments(playerSegments);
					//lastUpdated = getLatestUpdate(incomingRemoteConfigValues);
				}

				// Add a delay to prevent infinite loop
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
		} catch (error) {
			console.error('Error fetching remote config data:', error);
		}
	}

	async function toggleSegmentEnvironment(segmentInstance: PlayerSegment) {
		try {
			let playerSegment: PlayerSegment = { ...segmentInstance, isTest: !segmentInstance.isTest };
			console.log('Updating segment status: ');
			console.log(playerSegment);
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + `/segment/${segmentInstance?.id}`;
			await axios.put(url, playerSegment, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } }).then((response) => {
				console.log(response);
			});
		} catch (error: any) {
			console.error(error);
			toast({
				title: 'Error Updating Segment Status',
				description: error.response?.data,
				status: 'error',
				duration: 10000,
				isClosable: true,
				position: 'top',
			});
		}
	}

	async function duplicateSegment(segmentData: PlayerSegment, numberOfDuplication: number = 1) {
		try {
			console.log('Duplicating Segment', segmentData);

			toast({
				title: `Duplicationg Segment: ${segmentData.name}`,
				description: `Creating ${numberOfDuplication} copies of the segment`,
				status: 'info',
				duration: 10000,
				isClosable: true,
				position: 'top',
			});

			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/segment';
			for (let i = 0; i < numberOfDuplication; i++) {
				let segmentDataCopy = { ...segmentData };
				segmentDataCopy.name = segmentDataCopy.name + String(i + 1);
				segmentDataCopy.id = 0;
				segmentDataCopy.uid = '';
				segmentDataCopy.userId = 0;
				segmentDataCopy.creationDate = 0;
				segmentDataCopy.lastUpdated = 0;
				segmentDataCopy.isTest = true;
				segmentDataCopy.isDeleted = false;
				segmentDataCopy.isOverWritten = false;

				await axios.post(url, segmentDataCopy, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } }).then((response) => {
					console.log(response);
				});
			}

			toast({
				title: 'Segment Duplicated',
				description: `${numberOfDuplication} copies of the segment have been created`,
				status: 'success',
				duration: 10000,
				isClosable: true,
				position: 'top',
			});
		} catch (error: any) {
			console.error(error);
			toast({
				title: 'Error Creating Segment',
				description: error.response?.data,
				status: 'error',
				duration: 10000,
				isClosable: true,
				position: 'top',
			});
		}
	}

	function showCheckMark(isActive: boolean, onClick: () => void) {
		return (
			<Flex justify="center" align="center" width="100%" height="100%">
				{isActive ? (
					<Icon as={BsFillCheckCircleFill} color={'green.200'} _hover={{ color: 'gray.300' }} onClick={onClick} />
				) : (
					<Icon as={CgCloseO} color={'red.200'} _hover={{ color: 'gray.300' }} onClick={onClick} />
				)}
			</Flex>
		);
	}

	function ViewButton({ onOpen }: { onOpen: () => void }) {
		return (
			<IconButton
				onClick={onOpen}
				aria-label="Add"
				icon={<ViewIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
				backgroundColor={'transparent'}
				_hover={{ bg: 'transparent', color: 'white' }}
			/>
		);
	}

	function PlusButton({ paddingTop, onOpen }: { paddingTop?: string; onOpen: () => void }) {
		return (
			<IconButton
				onClick={onOpen}
				paddingTop={paddingTop}
				aria-label="Add"
				icon={<PlusSquareIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
				backgroundColor={'transparent'}
				_hover={{ bg: 'transparent', color: 'white' }}
			/>
		);
	}

	const deleteSegmentModalOpen = (disc: { onOpen: () => void }, segmentInstance: PlayerSegment) => {
		setSegmentToDelete(segmentInstance);
		disc.onOpen();
	};

	return (
		<Box border={'2px'} borderRadius={'5px'} borderColor={'gray.600'}>
			{deleteSegmentDisclosure.isOpen && segmentToDelete !== undefined && (
				<DeleteSegmentModal segmentInstance={segmentToDelete} disclosure={deleteSegmentDisclosure} />
			)}

			{editSegment.isOpen && (
				<SegmentModal title={'Edit Segment'} isOpen={editSegment.isOpen} onClose={editSegment.onClose} segmentInstance={selectedSegment} />
			)}

			{createSegment.isOpen && <SegmentModal title={'Create Segment'} isOpen={createSegment.isOpen} onClose={createSegment.onClose} />}

			<VStack spacing={1} align="stretch">
				<HStack flex={1} align={'center'}>
					<Box paddingLeft={'10px'}>
						<strong>User Segments</strong>
					</Box>
					<PlusButton onOpen={createSegment.onOpen} paddingTop={'0px'} />
				</HStack>
				<TableContainer>
					<Table variant="simple" size="md" border={'0px'}>
						<Thead>
							<Tr key={'header'}>
								<Th width="200px" cursor="pointer" maxW="200px" minW="200px">
									<Flex align="center" gap="15px" height="20px">
										<Tooltip label="Search by Name" placement="top">
											<Icon
												as={SearchIcon}
												boxSize={4}
												cursor="pointer"
												onClick={(e) => {
													e.stopPropagation();
													setIsSearchActive(!isSearchActive);
												}}
											/>
										</Tooltip>
										{isSearchActive && (
											<Input
												size="sm"
												placeholder="Search..."
												value={searchQuery}
												onChange={(e) => setSearchQuery(e.target.value)}
												autoFocus
												width="100%"
											/>
										)}
										{!isSearchActive && (
											<span onClick={() => handleSort('name')}>Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}</span>
										)}
									</Flex>
								</Th>
								<Th width="250px" maxW="250px">
									Description
								</Th>
								<Th width="200px" maxW="200px" onClick={() => handleSort('creationDate')} cursor="pointer">
									Creation Date {sortedData.length > 0 && (sortField === 'creationDate' ? (sortDirection === 'asc' ? '↑' : '↓') : '')}
								</Th>
								<Th width="150px" maxW="150px" onClick={() => handleSort('lastUpdated')} cursor="pointer">
									Last Update {sortedData.length > 0 && (sortField === 'lastUpdated' ? (sortDirection === 'asc' ? '↑' : '↓') : '')}
								</Th>
								<Th width="150px" maxW="150px" onClick={() => handleSort('isTest')} cursor="pointer">
									<Flex justify="center" align="center" width="100%" height="100%">
										Production {sortedData.length > 0 && (sortField === 'isTest' ? (sortDirection === 'asc' ? '↑' : '↓') : '')}
									</Flex>
								</Th>
								{/*<Th width="50px"><Flex justify="center" align="center" width="100%" height="100%">Status</Flex></Th>*/}
								<Th width="50px" maxW="150px">
									<Flex justify="center" align="center" width="100%" height="100%">
										View
									</Flex>
								</Th>
								<Th width="50px" maxW="150px">
									<Flex justify="center" align="center" width="100%" height="100%">
										Delete
									</Flex>
								</Th>
								<Th width="50px" maxW="150px">
									<Flex justify="center" align="center" width="100%" height="100%">
										Action
									</Flex>
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{sortedData.map((field: PlayerSegment, i: number) => (
								<Tr key={i}>
									<Td
										width="200px"
										maxW="200px"
										overflow="hidden"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
										position="relative"
										_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
									>
										{field.name}
									</Td>
									<Td
										width="200px"
										maxW="250px"
										overflow="hidden"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
										position="relative"
										_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
									>
										{field.description}
									</Td>
									<Td width="200px" maxW="200px">
										{formatTimestamp(field.creationDate)}
									</Td>
									<Td width="150px" maxW="150px">
										{formatTimestamp(field.lastUpdated)}
									</Td>
									<Td width="50px" maxW="150px">
										{showCheckMark(!field.isTest, () => toggleSegmentEnvironment(field))}
									</Td>
									{/*                                <Td width="50px"><Flex justify="center" align="center" width="100%" height="100%">
                                    <Icon as={BsFillCheckCircleFill} color={'green.200'} _hover={{ color: 'gray.300' }} /></Flex>
                                </Td>*/}
									<Td>
										<Flex justify="center" align="center" width="100%" height="100%">
											<ViewButton onOpen={() => multiFunction(editSegment, field)} />
										</Flex>
									</Td>
									<Td width="50px">
										<Flex justify="center" align="center" width="100%" height="100%">
											<Button
												onClick={() => {
													deleteSegmentModalOpen(deleteSegmentDisclosure, field);
												}}
												leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
												variant="simple"
											/>
										</Flex>
									</Td>
									<Td textAlign="center" verticalAlign="middle" width="50px">
										<Menu>
											<MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
												<IconButton
													aria-label="Add"
													icon={<PiDotsThreeOutlineVerticalFill color={'gray.500'} />}
													backgroundColor={'transparent'}
													_hover={{ bg: 'transparent', color: 'white' }}
												/>
											</MenuButton>
											<MenuList>
												<MenuGroup title="Experimental Actions" fontSize={'m'} fontWeight={'bold'} color={'orange.500'}>
													<HStack px={3} py={1}>
														<Button size="sm" colorScheme="blue" onClick={() => duplicateSegment(field, copies)}>
															Duplicate Segment
														</Button>
														<Input
															size="sm"
															width="50px"
															type="number"
															min={1}
															value={copies}
															onChange={(e) => setCopies(parseInt(e.target.value) || 1)}
															placeholder="1"
														/>
													</HStack>
												</MenuGroup>
											</MenuList>
										</Menu>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</VStack>
		</Box>
	);
}

function SegmentationView() {
	return (
		<Grid
			height={'1px'}
			width={'100%'}
			templateRows="repeat(4, 1fr)"
			templateColumns="repeat(5, 1fr)"
			gap={2}
			paddingLeft={'12px'}
			paddingRight={'10px'}
		>
			<GridItem colSpan={6} padding={'10px'}></GridItem>
			<GridItem paddingLeft={'5px'} paddingRight={'5px'} colSpan={6}>
				<SegmentationView2 />
			</GridItem>
		</Grid>
	);
}
export default SegmentationView;
