import { LockIcon } from '@chakra-ui/icons';
import { Box, Button, Container, Center, Flex, FormControl, FormLabel, Input, ChakraProvider, Heading } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import NavBar from '../navbar';
import config from '../config';
import globalStore from '../store';
import { Formik, Form, Field } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { loginFormSchema } from '@repo/alictus-common/validation/login_schema';
import { LoginCred } from '@repo/alictus-common/types/login/login_cred';
import Turnstile from 'react-turnstile';

export default function LoginView() {
	const [error, setError] = useState<string>('');
	const [captchaToken, setCaptchaToken] = useState<string | null>(null);
	const [userToken, setUserToken] = useState<string | null>(null);
	const [tokenIsValid, setTokenIsValid] = useState<boolean>(false);
	const [roles, setRoles] = useState<string[]>([]);
	const [isLoginReady, setIsLoginReady] = useState<boolean>(false);
	const setJWTToken = globalStore((state) => state.setJWTToken);
	const setUser = globalStore((state) => state.setUser);

	const handleLogin = async (values: LoginCred) => {
		if (!captchaToken) {
			setError('Please complete the CAPTCHA.');
			return;
		}

		try {
			const response = await axios.post(`${config.API_ENDPOINT}/login`, {
				...values,
				captchaToken,
			});

			if (response.status === 200) {
				setUserToken(response.data.token);
				setRoles(response.data.roles);
				setJWTToken(response.data.token);
				sessionStorage.setItem('dashboardUserInfo', JSON.stringify(response.data));
				setTokenIsValid(true);
				setError('');
				setIsLoginReady(false);
				setCaptchaToken(null);
				setUser({ id: response.data.id, name: response.data.name, email: response.data.email, roles: response.data.roles });
			}
		} catch (e) {
			console.error('Login failed:', e);
			setError('Login failed. Please check your credentials.');
		}
	};

	useEffect(() => {
		const userData = sessionStorage.getItem('dashboardUserInfo');
		if (userData) {
			const data = JSON.parse(userData);
			if (data.token) {
				const decodedToken = JSON.parse(atob(data.token.split('.')[1]));
				const currentTime = Math.floor(Date.now() / 1000);

				if (decodedToken.exp && decodedToken.exp > currentTime) {
					setUserToken(data.token);
					setJWTToken(data.token);
					setRoles(data.roles || []);
					setTokenIsValid(true);
					setUser({ id: data.id, name: data.name, email: data.email, roles: data.roles });
				} else {
					sessionStorage.removeItem('dashboardUserInfo');
				}
			}
		}
	}, []);

	if (tokenIsValid && userToken) {
		return <NavBar userToken={userToken} setUserToken={setUserToken} roles={roles} avatarUrl={''} />;
	}

	return (
		<ChakraProvider>
			<Center minHeight="100vh">
				<Box p={8} maxWidth="500px" borderWidth={1} borderRadius={8} boxShadow="lg">
					<Container maxW="xs">
						<Flex direction="column" align="center" mt={20}>
							<LockIcon w={8} h={8} marginBottom="40px" />
							<Heading fontSize="2xl">Login Page</Heading>
							<Box mt={4} w="100%">
								{error && (
									<Box color="red.500" mb={4}>
										{error}
									</Box>
								)}
								<Formik
									initialValues={{ email: '', password: '' }}
									validationSchema={toFormikValidationSchema(loginFormSchema)}
									onSubmit={(values) => {
										handleLogin(values);
										setIsLoginReady(false);
									}}
								>
									{({ errors, touched }) => (
										<Form>
											<FormControl isInvalid={!!errors.email && touched.email} isRequired>
												<FormLabel>Email Address</FormLabel>
												<Field as={Input} id="email" name="email" type="email" placeholder="Email" />
												{errors.email && touched.email && (
													<Box color="red.500" mt={1}>
														{errors.email}
													</Box>
												)}
											</FormControl>
											<FormControl isInvalid={!!errors.password && touched.password} isRequired mt={4}>
												<FormLabel>Password</FormLabel>
												<Field as={Input} id="password" name="password" type="password" placeholder="Password" />
												{errors.password && touched.password && (
													<Box color="red.500" mt={1}>
														{errors.password}
													</Box>
												)}
											</FormControl>
											<Box mt={4}>
												<Turnstile
													sitekey="0x4AAAAAAA1T2FYLhTmu0uRn"
													onVerify={(token: string) => {
														setCaptchaToken(token);
														setIsLoginReady(true);
													}}
													onExpire={() => setCaptchaToken(null)}
												/>
											</Box>
											<Button colorScheme="blue" mt={6} width="full" type="submit" isDisabled={!isLoginReady}>
												Login
											</Button>
										</Form>
									)}
								</Formik>
							</Box>
						</Flex>
					</Container>
				</Box>
			</Center>
		</ChakraProvider>
	);
}
